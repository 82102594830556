let loadflag4 = 0;
let tid2 = setInterval(function() {
  clearInterval(tid2);
  if (loadflag4 == 0) {
    // yandex map
    ymaps.ready(function() {
      let myMap = new ymaps.Map('map', {
        center: [55.907158, 37.550729],
        zoom: 12
      },
      {
        searchControlProvider: 'yandex#search'
      }),

      // Создаём макет содержимого.
      MyIconContentLayout = ymaps
        .templateLayoutFactory
        .createClass('<div style="color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>'),

      myPlacemarkWithContent = new ymaps.Placemark([55.907158, 37.550729], {
        hintContent: 'Автосалон ИнкарАвто',
        balloonContent: `127253, г. Москва, ул. Псковская, д. 13\n<br>Ежедневно с 09:00 до 21:00`,
        iconContent: ''
      },
      {
        // Опции.
        // Необходимо указать данный тип макета.
        iconLayout: 'default#imageWithContent',
        // Своё изображение иконки метки.
        iconImageHref: '/static/assets/images/map-marker.svg',
        // Размеры метки.
        iconImageSize: [64, 64],
        // Смещение левого верхнего угла иконки относительно
        // её "ножки" (точки привязки).
        iconImageOffset: [ - 35, -60],
        // Смещение слоя с содержимым относительно слоя с картинкой.
        iconContentOffset: [15, 15],
        // Макет содержимого.
        iconContentLayout: MyIconContentLayout
      });

      myMap.geoObjects.add(myPlacemarkWithContent);
    });
    loadflag4++;
  }
},
1500);